// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "21.2.0",
  "buildNumber": "12498",
  "gitSha": "16e1ac6956cd5177a99dc3fe33503661881c354f",
  "fullVersion": "21.2.0-12498",
  "formattedVersion": "21.2.0 (12498-16e1ac6956cd5177a99dc3fe33503661881c354f)",
  "copyrightYear": "2021"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/21.2.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/21.2.0/${stripLeadingPrefix(suffix, '#')}`
}
    
